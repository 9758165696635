// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// = require_tree .

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "jquery";
import "bootstrap";
import "moment";
import "select2";
import "select2/dist/css/select2.css";
import "channels";
import "ion-rangeslider";
import "@client-side-validations/client-side-validations/src";
import "common.js";
Rails.start();
Turbolinks.start();
ActiveStorage.start();
require("trix");
require("@rails/actiontext");

$(document).on("turbolinks:load", function () {
  gtag("config", "G-LESWN66DW3", { page_location: event.data.url });
});

$(document).on("turbolinks:load", function () {
  const showLoader = () => {
    $("body").css("opacity", 0.5);
    $("body").css("pointer-events", "none");
    $("body").append(
      `<div id="loader">
        <div class="spinner-grow" style="position:fixed;top:50%;left:50%;" role="status">
          <span class="sr-only">Loading...</span>
        </div
      </div>`
    );
  };
  const removeLoader = () => {
    $("body").css("opacity", 1);
    $("body").css("pointer-events", "");
    $("#loader").remove();
  };
  if ($(".pagination").length) {
    $(window).scroll(function () {
      var url = $(".pagination .next_page").attr("href");
      if (
        url &&
        $(window).scrollTop() > $(document).height() - $(window).height() - 50
      ) {
        showLoader();
        $(".pagination").text("Please Wait.....");
        return $.getScript(window.location.href + url, () => {
          removeLoader();
        });
      }
    });
    return $(window).scroll();
  }
});
