const noResultsImg = require("../assets/images/no-img.svg");
const searchIcon = require("../assets/images/search-icon.svg");
const arrowRightIcon = require("../assets/images/Arrow-Right.svg");
const queryString = require("query-string");

$(document).on("turbolinks:load", () => {
  var params = new URLSearchParams(window.location.search);
  if (params.has("q[s]")) {
    const [orderBy, order] = params.get("q[s]").split(" ");
    $("#length-sort .dropdown-select").val(orderBy);
    $("#swap-sort .dropdown-select").val(order);
  }

  sorTagsList();

  $('#search-bar-main').focus(function(){  $(this).attr('placeholder', 'what?')}).blur(function(){  $(this).attr('placeholder', 'try me')})

  $(".select2").select2();

  $("#length-sort").on("change", function () {
    const order_by = $("#length-sort option").filter(":selected").val();
    if (order_by === "title") {
      $(".sort_link")[0].click();
    } else if (order_by === "reading_time") {
      $(".sort_link")[1].click();
    } else if (order_by === "reading_level") {
      $(".sort_link")[2].click();
    } else if (order_by === "random") {
      $(".sort_link")[3].click();
    } else {
      window.location.href = "/";
    }
  });

  $("#swap-sort").on("change", function () {
    const ord = $("#swap-sort option").filter(":selected").val();
    if (ord === "asc") {
      $(".desc")[0]?.click();
    } else {
      $(".asc")[0]?.click();
    }
  });

  $("#open-nav").on("click", function (event) {
    if ($(".filters").hasClass("dismiss")) {
      if ($(".filters").hasClass("slide-out")) {
        $(".filters").removeClass("slide-out");
      }
      $(".filters").removeClass("dismiss").addClass("selected").show();
    }
    event.preventDefault();
  });

  $("#close-nav").on("click", function (event) {
    if ($(".filters").hasClass("selected")) {
      $(".filters")
        .removeClass("selected")
        .addClass("slide-out")
        .addClass("dismiss");
    }
    event.preventDefault();
  });

  $(".mobile-menu button").on("click", function () {
    $(".nav-links-right").toggle();
  });

  $(".js-range-slider-wl").ionRangeSlider({
    skin: "round",
    min: 0,
    max: 7,
    from: 0,
    to: 7,
    prettify_separator: ",",
    prettify: (n) => {
      return n + ' min'
    },
    onFinish: () => fetchData(),
  });

  $(".js-range-slider-gl").ionRangeSlider({
    skin: "round",
    min: 0,
    max: 18,
    from: 0,
    to: 18,
    prettify_separator: ",",
    onFinish: () => fetchData(),
  });

  function prepareTags(tags) {
    var d = "";
    tags.forEach((tag) => {
      d += `<a href="?tags[]=${tag.id}" class="tags">${tag.essay_tag}</a> `;
    });
    return d;
  }

  function sorTagsList() {
    $(".unstyled li").sort(sort_li).appendTo(".unstyled");
    function sort_li(a, b) {
      return $(b).data("count") > $(a).data("count") ? 1 : -1;
    }
  }

  function prepareCards(data) {
    var content = "";
    let redirectPath = ''
    if($('a[href="/users/sign_out"').length != 0) { // user is logged in
      redirectPath = `/users/${$('input[name="user_id"]')[0].value}/essays/new`
    } else {
      redirectPath = '/users/sign_up'
    }
    if (!data.length)
      content = `<div class="no-result-wrapper">
                                    <div class="no-result-img">
                                      <img src="${noResultsImg}" />
                                    </div>
                                    <div class="no-result-text">
                                      <h1>Uncharted Territory!</h1>
                                      <p>You clearly have thoughts on this matter that no one else apparently does. <a class="learn-more" style="font-size: 16px" href=${redirectPath}>Write about it!</a></p>
                                    </div>
                                  </div>`;

    data.forEach((essay) => {
      let padding_top = 'pt-0'
      content += `<div class="essay" onclick="redirectToEssay(${essay.id})">
                      <div class="essay-header">`
              if (essay.title.length > 0) {
                content +=  `<h3><a href='/essays/${essay.id}' class='essay-title-nav'>${essay.title}</a></h3>`
                padding_top = ''
              }
      content +=        `<p class="essay-text ${padding_top}">
                          ${essay.humanized_rich_text}...
                          <a href="/essays/${essay.id}" class="read-more">
                            Read more <span><img src="${arrowRightIcon}" /></span>
                          </a>
                        </p>

                        <div class="essay-tags mt-3">
                          <span>
                            ${prepareTags(essay.tags)}
                          </span>
                        </div>

                        <div class="essay-stats mt-4">
                          <span class="stat-two mr">
                            By: <a href="/essays?username=${essay.user_name}"
                                  <span class="bold">@${essay.user_name} </span>
                                </a>
                          </span>
                          <span class="stat mr">
                            Reading Time: <span class="bold"> ${
                              essay.reading_time < 2 ? (Math.ceil((essay.reading_time * 60 + 3) / 10) * 10) + ' seconds': Math.floor(essay.reading_time) + ' minutes'
                            }</span>
                          </span>`
          if (essay.reading_level != null) {
            content += `<span class="stat">
                  Writing grade level: <span class="bold"> ${
                    essay.reading_level
                  } </span>
                </span>`
          }
            content += `</div></div>`;
      if(essay.replies.length > 0) {
        content += `
        <section class="essay-replies mt-4">
        <h6>Retorts</h6>
        <div class="retorts">`
          essay.replies.forEach((retort) => {
            content += `
            <div class="essay-replies-wrapper" onclick="redirectToEssay(${retort.id})">
            <h5>${truncateString(retort.title, 20)}</h5>
            <p>
              <a href="/essays?username=${retort.user_name}">
                <span class="bold essay-replies-username">@${retort.user_name}:</span>
              </a>${truncateString(retort.humanized_rich_text, 80)}
            </p>
            </div>`
          })
        content += `</section>`
      }
      content += '</div>'
    });
    return content;
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }

    return str.slice(0, num) + '...'
  }

  $("body").delegate(".form-check-input", "click", function () {
    saerchEssayWithFilters();
  });

  saerchEssayWithFilters = () => {
    var params = new URLSearchParams(window.location.search);

    var tags = [];
    $(".form-check-input").each(function () {
      if ($(this).is(":checked")) {
        tags.push(parseInt($(this).val()));
      }
    });

    const state = queryString.parse(window.location.search);
    state["tags[]"] = tags;
    if ($("#essay-seach-on-user :selected").val() !== "") {
      state["username"] = $("#essay-seach-on-user :selected").val();
    }
    const url = queryString.stringifyUrl({
      url: window.location.origin + "/essays",
      query: state,
    });
    window.history.pushState("", "", url);
    fetchData();
  };

  const showLoader = () => {
    $("body").css("opacity", 0.5);
    $("body").css("pointer-events", "none");
    $("body").append(
      `<div id="loader">
        <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div
      </div>`
    );
  };

  const removeLoader = () => {
    $("body").css("opacity", 1);
    $("body").css("pointer-events", "");
    $("#loader").remove();
  };

  const fetchData = () => {
    var rtData = $(".js-range-slider-wl").data();
    var glData = $(".js-range-slider-gl").data();
    var tags = [];
    $(".form-check-input").each(function () {
      if ($(this).is(":checked")) tags.push(parseInt($(this).val()));
    });
    const payload = {
      min: rtData.from,
      max: rtData.to,
      min_level: glData.from,
      max_level: glData.to,
      tags,
      username: $("#essay-seach-on-user :selected").val(),
    };

    showLoader();
    $.get("/essay-in-range", payload).done(function (data, status) {
      var arr = [];
      let selected = [];
      for (key in data["tags"]) {
        let obj = Object.assign(data["tags"][key], { name: key });
        obj.selected ? selected.push(obj) : arr.push(obj);
      }
      arr.sort((obj1, obj2) => (obj1.count < obj2.count) ? 1 : (obj1.count > obj2.count) ? -1 : 0);
      // code for sorting on name
      // selected.sort((obj1, obj2) => (obj1.essay_tag[0] < obj2.essay_tag[0]) ? -1 : (obj1.essay_tag[0] > obj2.essay_tag[0]) ? 1 : 0);
      arr = selected.concat(arr)
      var container = document.getElementById("essays-here");
      container.innerHTML = prepareCards(data["essays"]);
      document.getElementById("tag-location").innerHTML = prepareTagsList(arr);
      if (window.location.search.length) {
        $('#clear-data').removeClass('d-none')
      } else {
        $('#clear-data').addClass('d-none')
      }
      removeLoader();
    });
  };

  prepareTagsList = (data) => {
    content = "";
    data.forEach((tag) => {
      content += `<li data-count=${tag.id}>
                  <input type="checkbox" name="tags[]" value=${tag.id} ${tag.checked} class='styled-checkbox form-check-input' id="styled-checkbox-${tag.id}">
                  <label for="styled-checkbox-${tag.id}">
                  ${tag.essay_tag} (
                    <span class="count-of-essays" data-id="${tag.id}"> ${tag.count} </span>
                    )
                    </label>
                    </li>`;
    });
    return content;
  };

  if (matchMedia("only screen and (min-width: 960px)").matches) {
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        document.getElementById("top-nav").style.height = "66px";
        document.getElementById("swap-text").style.top = "7px";
      } else {
        document.getElementById("top-nav").style.height = "92px";
        document.getElementById("swap-text").style.top = "24px";
      }
    }

    scrollFunction();
  }

  $("#clear-search").on("click", () => {
    window.location.href = "/essays";
  });

  $(
    "#q_title_or_word_count_or_reading_level_or_action_text_rich_text_body_cont"
  ).on("keyup", () => {
    if ($("#clear-search").length) {
      $("#clear-search").removeAttr("type").attr("type", "submit");
      $("#clear-search").children("img").attr("src", searchIcon);
    }
  });

  $("body").on("click", function (event) {
    if (
      $(event.target).is("#open-nav") === false &&
      $(event.target).closest(".filters").length === 0
    ) {
      if ($(".filters").hasClass("selected")) {
        $(".filters")
          .removeClass("selected")
          .addClass("slide-out")
          .addClass("dismiss");
        event.preventDefault();
      }
    }
  });

  window.setTimeout(function () {
    $(".alert-dismissible")
      .fadeTo(500, 0)
      .slideUp(500, function () {
        $(this).remove();
      });
  }, 2000);

  add_list_to_items = (field) => {
    event.preventDefault();
    if (field != "") {
      input = $(`#input-${field}`).val();
      $(`#input-${field}`).val("");
    }

    if (input != "") {
      let newEl = $(
        `<div class='d-flex mt-1 ${input
          .toLowerCase()
          .replace(
            /[^a-zA-Z0-9]+/g,
            "-"
          )}'><input class="field form-control tag-field" type="text" value='${input}' name='essay[new-${field}][]' readonly><a onclick="removeTag('${input}');" class='m-2 cursor-pointer'><i class="fa fa-close fs-2x"></i></a></div>`
      );
      $(`#${field}-list`).append(newEl);
    }
  };

  removeTag = (value) => {
    event.preventDefault();
    $(`.${value.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")}`).remove();
  };

  removeMediaLink = (value)=>{
    event.preventDefault();
    $(`#${value}`).remove();
  }

  $("#new-reg-user-name").on("blur", () => {
    username = $("#new-reg-user-name").val();
    $.ajax({
      type: "GET",
      url: "/essays/users?name=" + username,
      dataType: "script",
      success: (response) => {
        if (response === "error") {
          $(".uniqueness-error").html("has already been taken.");
        } else if (response === "success") {
          $(".uniqueness-error").html("");
        }
      },
    });
  });
  removeWelcomeContainer = () => {
    $(".welcome-container-head").hide();
    $.ajax({
      type: "GET",
      url: "/essays/update_session",
      dataType: "script",
    });
  };
});
